import { gql } from '@apollo/react-hooks'

export const ADD_PRODUCT = gql`
  mutation AddProduct($data: CreateProductInput!) {
    result: createPartnerProduct(data: $data) {
      id
      partnerId
      image
      name
      price
      status
      description
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($data: UpdateProductInput!, $id: Int!) {
    result: updatePartnerProduct(data: $data, id: $id) {
      id
      createdAt
      partnerId
      updatedAt
      image
      price
      name
      status
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: Int!) {
    result: deletePartnerProduct(id: $id) {
      id
    }
  }
`
