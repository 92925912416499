import { gql } from '@apollo/react-hooks'

export const LIST_USERS = gql`
  query Users($filter: FilterOptions) {
    result: users(filter: $filter) {
      id
      name
      email
    }
  }
`

export const GET_USER = gql`
  query User($id: Int!) {
    result: user(id: $id) {
      id
      name
      email
    }
  }
`
