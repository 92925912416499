import { gql } from '@apollo/react-hooks'

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($data: UpdateOrderInput!, $id: Int!) {
    result: updateOrder(data: $data, id: $id) {
      id
      status
    }
  }
`
