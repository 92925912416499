import { gql } from '@apollo/react-hooks'

export const LIST_ORDERS = gql`
  query MyOrders($filter: FilterOptions!) {
    result: myOrders(filter: $filter) {
      id
      payed
      status
      value
      user {
        id
        name
      }
    }
  }
`

export const GET_ORDER = gql`
  query Product($id: Int!) {
    result: product(id: $id) {
      id
      image
      name
      price
      status
      description
    }
  }
`
