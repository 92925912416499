import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from '@unform/core'
import { CFormInput, CFormSelect, CFormTextarea, CInputGroup, CInputGroupText } from '@coreui/react'

export default function Input({ name, className, groupText, ...rest }) {
  const { type, options } = rest
  const inputRef = useRef(null)
  const { fieldName, defaultValue, error, registerField } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: (ref) => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])
  return (
    <div className={className}>
      <CInputGroup>
        {groupText && <CInputGroupText>{groupText}</CInputGroupText>}

        {type === 'textarea' && (
          <CFormTextarea ref={inputRef} defaultValue={defaultValue} {...rest} />
        )}
        {type === 'select' && (
          <CFormSelect ref={inputRef} defaultValue={defaultValue} {...rest}>
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
          </CFormSelect>
        )}
        {['number', 'text', 'email', 'password'].includes(type) && (
          <CFormInput ref={inputRef} defaultValue={defaultValue} {...rest} />
        )}
      </CInputGroup>

      {error && (
        <div className="mt-1">
          <span style={{ color: 'red', marginLeft: '1rem', marginTop: '1rem', fontSize: 14 }}>
            {error}
          </span>
        </div>
      )}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
}
