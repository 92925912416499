import { gql } from '@apollo/react-hooks'

export const LIST_PRODUCT = gql`
  query Products($filter: FilterOptions) {
    result: myProducts(filter: $filter) {
      id
      image
      name
      price
      status
      description
    }
  }
`

export const GET_PRODUCT = gql`
  query Product($id: Int!) {
    result: product(id: $id) {
      id
      image
      name
      price
      status
      description
    }
  }
`
