export const TOKEN_KEY = '@megafielpartner:token'
export const USER_KEY = '@megafielpartner:user'

export const setUser = (userData) => localStorage.setItem(USER_KEY, JSON.stringify(userData))
export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token)

export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const getUser = () => JSON.parse(localStorage.getItem(USER_KEY))

export const eraseLocalStorage = () => localStorage.clear()

export const isAuthenticated = () => getToken() !== null
