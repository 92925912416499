import { gql } from '@apollo/react-hooks'

export const ADD_USER = gql`
  mutation AddUser($data: CreateUserInput!) {
    result: createUserPartner(input: $data) {
      id
      name
      email
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput!, $id: Int!) {
    result: updateUser(input: $data, id: $id) {
      id
      name
      email
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    result: deleteUser(id: $id) {
      id
    }
  }
`
