import { gql } from '@apollo/react-hooks'

export const CREATE_SESSION = gql`
  mutation LoginPartner($data: AuthInput!) {
    result: loginPartner(data: $data) {
      token
      user {
        id
        name
        email
        role
      }
    }
  }
`
