import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const cache = new InMemoryCache()
const httpLink = new HttpLink({ uri: process.env.REACT_APP_API })

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('@megafielpartner:token')
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  return forward(operation)
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError && networkError.message === 'Failed to fetch') {
    localStorage.removeItem('@megafielpartner:user')
    localStorage.removeItem('@megafielpartner:token')

    window.location.reload()
  }
})

const client = new ApolloClient({
  link: errorLink.concat(authMiddleware.concat(httpLink)),
  cache,
})

export default client
