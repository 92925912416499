import { useMutation } from '@apollo/client'
import { cilLockLocked, cilAt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { Form } from '@unform/web'
import React, { useCallback, useContext } from 'react'
import Input from 'src/components/InputForm'
import { ToastContext } from 'src/hooks/toast'
import { setToken, setUser } from 'src/services/auth'
import getMessageError from 'src/services/getMessageError'
import history from 'src/utils/history'
import logo from 'src/assets/brand/logo_horizontal.png'

import { CREATE_SESSION } from 'src/utils/queries'
import { Link } from 'react-router-dom'

const Login = () => {
  const [createSession] = useMutation(CREATE_SESSION)

  const { addToast } = useContext(ToastContext)

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const { data: response } = await createSession({
          variables: {
            data,
          },
        })

        setUser(response.result.user)
        setToken(response.result.token)

        history.push('/')
      } catch (err) {
        addToast({ message: 'Erro', type: 'error', description: getMessageError(err) })
      }
    },
    [createSession, addToast],
  )

  return (
    <div className="bg-dark min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <img
        src={logo}
        alt="Logo"
        style={{ maxWidth: 300, maxHeight: 300, width: 'auto', height: 'auto' }}
        className="mb-3"
      />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Form onSubmit={handleSubmit}>
                    <h3>Login Parceiro</h3>
                    <br />
                    <Input
                      name="email"
                      placeholder="Email"
                      autoComplete="username"
                      type="email"
                      className="mb-3"
                      groupText={<CIcon icon={cilAt} />}
                    />
                    <Input
                      name="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      className="mb-3"
                      groupText={<CIcon icon={cilLockLocked} />}
                    />
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                    </CRow>
                  </Form>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Cadastre-se</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Cadastrar-se Agora
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
