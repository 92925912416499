import React from 'react'
import { AnimatePresence } from 'framer-motion'

import { Toast } from './Toast'

import { Container } from './styles'

export function ToastContainer({ messages }) {
  return (
    <Container style={{ zIndex: messages.length > 0 ? 10000 : 0 }}>
      <AnimatePresence>
        {messages.map((message) => (
          <Toast key={message.id} message={message} />
        ))}
      </AnimatePresence>
    </Container>
  )
}
