import React from 'react'

// const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'))

//Extract
const ExtractTable = React.lazy(() => import('src/views/Extract/Table'))

//Orders
const OrdersTable = React.lazy(() => import('src/views/Orders/Table'))

//Products
const ProductTable = React.lazy(() => import('src/views/Products/Table'))
const ProductForm = React.lazy(() => import('src/views/Products/Form'))

//Promotions
const PromotionTable = React.lazy(() => import('src/views/Promotions/Table'))
const PromotionForm = React.lazy(() => import('src/views/Promotions/Form'))

//Users
const UserTable = React.lazy(() => import('src/views/Users/Table'))
const UserForm = React.lazy(() => import('src/views/Users/Form'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },

  //Orders
  { path: '/orders', name: 'Pedidos/Ordens', component: OrdersTable },

  //Extract
  { path: '/extract', name: 'Extrato', component: ExtractTable },

  //Products
  { path: '/products', name: 'Produtos', component: ProductTable, exact: true },
  { path: '/products/add', name: 'Adicionar Produto', component: ProductForm, exact: true },
  { path: '/products/edit/:id', name: 'Editar Produto', component: ProductForm, exact: true },

  //Promotions
  { path: '/promotions', name: 'Promoções', component: PromotionTable, exact: true },
  { path: '/promotions/add', name: 'Adicionar Promoção', component: PromotionForm, exact: true },
  { path: '/promotions/edit/:id', name: 'Editar Promoção', component: PromotionForm, exact: true },

  //Users
  { path: '/users', name: 'Usuários', component: UserTable, exact: true },
  { path: '/users/add', name: 'Adicionar Usuário', component: UserForm, exact: true },
  { path: '/users/edit/:id', name: 'Editar Usuário', component: UserForm, exact: true },
]

export default routes
