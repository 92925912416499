import React, { useCallback, useContext } from 'react'
import { CButton, CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { Form } from '@unform/web'
import Input from 'src/components/InputForm'
import logo from 'src/assets/brand/logo_horizontal.png'
import { useRef } from 'react'
import * as Yup from 'yup'
import getValidationErrors from 'src/services/getValidationErros'
import { useMutation } from '@apollo/client'
import { ADD_PARTNER } from 'src/utils/mutations'
import { ToastContext } from 'src/hooks/toast'
import history from 'src/utils/history'
import getMessageError from 'src/services/getMessageError'

const Register = () => {
  const formRef = useRef(null)
  const { addToast } = useContext(ToastContext)
  const [createPartner] = useMutation(ADD_PARTNER)

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          partnerName: Yup.string().required('Nome Fantasias Obrigatório'),
          partnerEmail: Yup.string()
            .email('Digite um email válido')
            .required('E-mail é obrigatório'),
          partnerCnpj: Yup.string()
            .length(14, 'Digite apenas os números do cnpj')
            .required('CNPJ é obrigatório'),
          partnerPhone: Yup.string().required('Telefone é obrigatório'),
          partnerWhatsapp: Yup.string().required('Telefone é obrigatório'),
          partnerAddress: Yup.string().required('Endereço é obrigatório'),
          partnerZipcode: Yup.string().required('CEP é obrigatório'),
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().email('Digite um email válido').required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas não são iguais. Por favor, confirme a senha.',
          ),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const partner = {
          name: data.partnerName,
          email: data.partnerEmail,
          cnpj: data.partnerCnpj,
          phone: data.partnerPhone,
          whatsapp: data.partnerWhatsapp,
          address: data.partnerAddress,
          zipcode: data.partnerZipcode,
        }

        const user = {
          name: data.name,
          email: data.email,
          password: data.password,
          role: 'partner_user',
        }

        const input = {
          partner,
          user,
        }

        await createPartner({ variables: { data: input } })

        addToast({
          message: 'Sucesso!',
          type: 'success',
          description: 'Conta criada com sucesso!',
        })

        history.push('/login')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)

          return
        }

        addToast({ message: 'Erro', type: 'error', description: getMessageError(err) })
      }
    },
    [createPartner, addToast],
  )

  return (
    <div className="bg-dark min-vh-100 d-flex pt-5 flex-column align-items-center">
      <img
        src={logo}
        alt="Logo"
        style={{ maxWidth: 300, maxHeight: 300, width: 'auto', height: 'auto' }}
        className="mb-3"
      />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <Form onSubmit={handleSubmit} ref={formRef}>
                  <h1>Registro Parceiro</h1>
                  <p className="text-medium-emphasis">Digite os Dados da Empresa</p>
                  <Input
                    name="partnerName"
                    placeholder="Nome Fantasia"
                    autoComplete="name"
                    type="text"
                    className="mb-2"
                    groupText="Nome Fantasia"
                  />
                  <Input
                    name="partnerEmail"
                    placeholder="Email"
                    autoComplete="email"
                    type="email"
                    className="mb-2"
                    groupText="@"
                  />
                  <Input
                    name="partnerCnpj"
                    placeholder="CNPJ"
                    autoComplete="cnpj"
                    type="text"
                    className="mb-2"
                    groupText="CNPJ"
                  />
                  <Input
                    name="partnerPhone"
                    placeholder="Telefone"
                    autoComplete="phone"
                    type="text"
                    className="mb-2"
                    groupText="Telefone"
                  />
                  <Input
                    name="partnerWhatsapp"
                    placeholder="Whatsapp"
                    autoComplete="phone"
                    type="text"
                    className="mb-2"
                    groupText="Whatsapp"
                  />
                  <Input
                    name="partnerAddress"
                    placeholder="Endereço"
                    autoComplete="address"
                    type="text"
                    className="mb-2"
                    groupText="Endereço"
                  />
                  <Input
                    name="partnerZipcode"
                    placeholder="CEP"
                    autoComplete="zipcode"
                    type="text"
                    className="mb-4"
                    groupText="CEP"
                  />

                  <p className="text-medium-emphasis">Digite os dados da conta principal</p>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Nome"
                    autoComplete="name"
                    groupText={<CIcon icon={cilUser} />}
                    className="mb-3"
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    groupText="@"
                    className="mb-3"
                  />
                  <Input
                    name="password"
                    type="password"
                    placeholder="Senha"
                    autoComplete="new-password"
                    groupText={<CIcon icon={cilLockLocked} />}
                    className="mb-3"
                  />
                  <Input
                    name="confirm_password"
                    type="password"
                    placeholder="Confirme a senha"
                    autoComplete="new-password"
                    groupText={<CIcon icon={cilLockLocked} />}
                    className="mb-4"
                  />
                  <div className="d-grid">
                    <CButton type="submit" color="success">
                      Criar Conta
                    </CButton>
                  </div>
                </Form>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Register
